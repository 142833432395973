import React from 'react';
import { BrowserRouter as Router, Route, Navigate, Routes, Form } from 'react-router-dom';

// Import your components
import SignUp from './modules/SignUp/SignUp';
import SignIn from './modules/SignIn/SignIn';
import SignOut from './modules/SignOut';
import VerifyCode from './modules/VerifyCode/VerifyCode';
import UserDetails from './modules/UserDetails';
import authService from './common/auth.service';
import RegistrationForm from './modules/RegistrationForm/RegistrationForm';
import ThankYou from './modules/ThankYou/ThankYou';

// Define your main App component
const App = () => {
  const auth = authService;
  console.log(auth);

  return (
    <Router>
      <Routes>
        <Route path="/sign-in" element={<SignIn auth={auth} />} />
        <Route path="/enter-secret-code" element={<VerifyCode auth={auth} />} />
        <Route path="/registration-form" element={<RegistrationForm auth={auth} />} />
        <Route path="/thank-you" element={<ThankYou auth={auth} />} />
        <Route path='/' element={<Navigate to="/sign-in" replace />}/>
      </Routes>
    </Router>
  );
};

export default App;
