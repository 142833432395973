export const datePickerObjToISOFormat = (date) => {
    const dateObj = typeof date?.toDate === 'function' ? date?.toDate() : date;
    // console.log('[datePickerObjToISOFormat] : ', dateObj);
    // console.log('[datePickerObjToISOFormat] : ', dateObj?.toLocaleDateString('sv-SW'));

    const dateSQLFormat = dateObj?.toLocaleDateString('sv-SW')?.slice(0,10);
    // const dateParts = dateStr.split("/");
    // const dateSQLFormat = dateParts[2] + "-" + dateParts[1] + "-" + dateParts[0];
    // console.log('dataSqlFormat: ', dateSQLFormat);
    return dateSQLFormat;    
}


 // turn db names into more readable text
 export const humanize = (str) =>  {

    // console.log('str: ', str?.length);
    
    var i, frags = str?.split('_');
    for (i=0; i<frags?.length; i++) {
        frags[i] = frags?.[i].charAt(0)?.toUpperCase() + frags?.[i]?.slice(1);
    }
    // console.log('frags: ', frags);
    const text = frags?.join(' ');
    // return text;
    
    // console.log('text: ', text?.length);
    const newText = text?.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1").trim();
    // console.log('newText: ', newText?.length);
    return newText
    
}
