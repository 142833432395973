import { AWS_API, userPoolClientId, userPoolId } from "../globalVariables";
import axios from "axios";

class ApiGatewayService {

    async getFormData(token,email){
        console.log(token, email);
        const config = {
            headers: {authorization: token, appclientid: userPoolClientId, userpoolid: userPoolId},
            params: { email: email }
        }
        const response = await axios.get(AWS_API + '/get-form-details/data', config);
        return response;
    }

    async postFormData(token,postDataInput){
        console.log(token, postDataInput);
        const headers = {
            headers: {authorization: token, appclientid: userPoolClientId, userpoolid: userPoolId},
        }
        const postData = {
            postData: postDataInput,
        }
        console.log('postdata: ', postData)
        const response = await axios.post(AWS_API + '/post-form-details/data', postData, headers);
        return response;
    }

}

const apiGatewayService = new ApiGatewayService();
export default apiGatewayService;
