import React from 'react';
// import { NavLink } from 'react-router-dom';
// import logo from '../assets/images/citra-header-logo.png';
import logo from '../assets/images/citra-logo.png';
import classes from './Logo.module.css';

const Logo = (props) => {
    return (
    // <NavLink to="/">
        <div className={classes.Logo}>
                <img src={logo} alt="ao-logo"/>
                {/* {['dev', 'reg'].includes(envName) && <div className={classes.EnvWarning}>{envName.toUpperCase()}</div>}                 */}
                {/* <h6>&nbsp;&nbsp;OSHC+</h6>   */}
        </div>
            
    // </NavLink>
)};

export default Logo;