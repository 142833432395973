import { Auth } from 'aws-amplify';

class AuthService {
  constructor() {
    this.cognitoUser = null;
    this.window = window;
  }

  async signIn(email) {
    this.cognitoUser = await Auth.signIn(email);
    return this.cognitoUser;
  }

  async signOut() {
    await Auth.signOut();
  }

  async answerCustomChallenge(answer) {
    // console.log("OLD COGNITO USER!!!!!!!!!");
    // console.log(this.cognitoUser);
    this.cognitoUser = await Auth.sendCustomChallengeAnswer(this.cognitoUser, answer);
    // console.log("NEW COGNITO USER!!!!!!!!!");
    // console.log(this.cognitoUser);
    return this.isAuthenticated();
  }

  async getPublicChallengeParameters() {
    return this.cognitoUser?.challengeParam;
  }

  async signUp(email, fullName) {
    const params = {
      username: email,
      password: this.getRandomString(30),
      // attributes: {
      //   name: fullName
      // }
    };
    return await Auth.signUp(params);
  }

  getRandomString(bytes) {
    const randomValues = new Uint8Array(bytes);
    this.window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues).map(this.intToHex).join('');
  }

  intToHex(nr) {
    return nr.toString(16).padStart(2, '0');
  }

  async isAuthenticated() {
    // try {
    //   const currentSession = await Auth.currentSession();
    //   console.log(currentSession);
    //   return true;
    // } catch {
    //   return false;
    // }

    //We basically check for the signInUserSession explicitly
    //If we do not have a sign in session we return error response
    return (this.cognitoUser.signInUserSession) ? true : false;
  }

  async getUserDetails() {
    if (!this.cognitoUser) {
      this.cognitoUser = await Auth.currentAuthenticatedUser();
    }
    return await Auth.userAttributes(this.cognitoUser);
  }

  getIdToken(){
    // await Auth.currentSession();
    if(this.cognitoUser){
      return this.cognitoUser.signInUserSession.idToken.jwtToken; //change the function name later
    }
    return null;
  }

  getUserEmail(){
    if(this.cognitoUser){
      return this.cognitoUser.username;
    }
    return null;
  }

  

}

const authService = new AuthService();
export default authService;
