import React from 'react';
import classes from './Header.module.css';
import Logo from '../../logo/Logo';

function Header() {
    return (
        <header className={classes.Header}>
            <div className={classes.LogoDiv}>
                <Logo />
            </div>
            <h1 className={classes.HeaderText}>Self Registration</h1>
        </header>
    );
}

export default Header;
