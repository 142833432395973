// Steps to deploy this app using aws cli:
// 1, Configure an aws profile named citra-dev
// 2, Go to root director on this folder on terminal
// 3. npm run build
// 4, aws --profile citra-dev s3 sync build/ s3://app.citras.mu

export const AWS_API = 'https://p5i5ubjg51.execute-api.ap-southeast-2.amazonaws.com';
export const region = 'ap-southeast-2';
export const userPoolId = 'ap-southeast-2_2tFjMLPpa';
export const userPoolClientId = '4ofq340kqa1pm0iorhm4s1bs5i';
